<template>
  <div>
    <ek-dialog
      placeholder="ابحث عن نقطة البيع المحدد"
      btnText="نقطة بيع جديد"
      :title="'اضافة نقطة البيع'"
      @ok="submit"
      @close="reset"
      @search="setSearch"
      ref="dialog"
    >
      <template #body>
        <validationObserver ref="sellPointForm">
          <b-form>
            <ek-input-text
              name="الاسم والكنبة"
              label="الاسم والكنية"
              v-model="sellPointDto.name"
              placeholder="ادخل الاسم والكنية"
              :rules="[{ type: 'required', message: 'اسم مطلوب' }]"
            ></ek-input-text>

            <ek-input-text
              type="number"
              name="رقم الهاتف"
              label="رقم الهاتف"
              placeholder="ادخل  رقم الهاتف"
              v-model="sellPointDto.phoneNumber"
              :rules="[
                { type: 'required', message: 'رقم مطلوب' },
                {
                  type: 'mobile',
                  message: 'رقم الهاتف غير صالح',
                },
              ]"
            ></ek-input-text>

            <ek-input-text
              name="كلمة السر"
              label="كلمة السر"
              v-model="sellPointDto.password"
              placeholder="ادخل  كلمة السر"
              :rules="[
                { type: 'required', message: 'كلمة المرور مطلوبة' },
                { type: 'min:8', message: 'كلمة المرور يجب ان تحوي 8 محارف على الاقل' },
              ]"
            ></ek-input-text>

            <ek-input-select
              name="sendTo"
              multiple
              placeholder="حدد  نوع الكلية"
              label="الكليات\المراحل التابعة لنقطة البيع  "
              textLabel="followTo"
              :options="facultyName"
              v-model="sellPointDto.facultyIds"
              :rules="[
                {
                  type: 'required',
                  message: ' الكلية مطلوبة',
                },
              ]"
            ></ek-input-select>

            <ek-input-text
              type="number"
              placeholder="ادخل  نسبة نقطة البيع"
              name="نسبة نقطة البيع"
              label="نسبة نقطة البيع"
              v-model="sellPointDto.rate"
              :rules="[
                {
                  type: 'required',
                  message: 'نسبة نقطة البيع مطلوبة',
                },
              ]"
            ></ek-input-text>
            <ek-input-text
              name="العنوان"
              placeholder="ادخل  العنوان"
              label="العنوان"
              v-model="sellPointDto.address"
              :rules="[{ type: 'required', message: 'العنوان مطلوب' }]"
            ></ek-input-text>
            <ek-input-text
              type="number"
              placeholder="ادخل  الحد الاعظمي لنقطة البيع"
              name="حد الاعظمي للبيع"
              label="حد الاعظمي للبيع"
              v-model="sellPointDto.moneyLimit"
              :rules="[
                {
                  type: 'required',
                  message: 'حد الاعظمي مطلوب',
                },
              ]"
                            prefix="ل.س" 
            ></ek-input-text>

            <b-form-group label="امكانية الحسم" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-slots"
                :options="options"
                v-model="sellPointDto.canDiscount"
                :aria-describedby="ariaDescribedby"
                name="امكانية الحسم"
              >
              </b-form-radio-group>
            </b-form-group>

            <ek-input-text
              v-if="sellPointDto.canDiscount"
              type="number"
              placeholder="ادخل  حد الحسم"
              name="حد الحسم"
              label="حد الحسم"
              v-model="sellPointDto.discountLimit"
              :rules="[
                                {
                                    type: 'required',
                                    message:
                                        '  حد الحسم مطلوب وحصراً قيمة موجبة وأصغر من 100',
                                },
                            ]"
                            :state="validation"
                            prefix="%" 
                        ></ek-input-text>
                        <b-form-invalid-feedback
                            :state="validation"
                            v-if="sellPointDto.canDiscount"
                        >
                            حد الحسم يجب أن يكون أصغر من 100
                        </b-form-invalid-feedback>
          </b-form>
        </validationObserver>
      </template>
    </ek-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { BToast } from "bootstrap-vue";

export default {
  data() {
    return {
      options: [
        { text: "مفعل", value: true },
        { text: "غير مفعل ", value: false },
      ],
    };
  },
  computed: {
    ...mapState({
      sellPointDto: (state) => state.sellPoints.sellPointDto,
      rateDto: (state) => state.settings.rateDto,
      facultyName: (state) => state.faculty.facultyName,
      settingDto: (state) => state.settings.settingDto,
    }),
    ...mapMutations(["RESET_SELL_POINTS_DTO"]),
    validation() {
            return this.sellPointDto.discountLimit <= 100;
        },
  },
  methods: {
    submit() {
      this.$refs["sellPointForm"].validate().then((suc) => {
        if(suc){
          this.addSellPoint(this.sellPointDto);
          this.$refs.dialog.close();
          this.$store.commit("RESET_SELL_POINTS_DTO"); 
        }
        else{
          const bToast = new BToast();
          bToast.$bvToast.toast(
            "تأكد من إدخال قيمة الحسم موجبة حصراً ",
            {
              title: "  لا يمكنك إضافة الحساب ",
              variant: "danger",
              toaster: "b-toaster-bottom-right",
              solid: true,
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
        }
      });
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["name", "phoneNumber", "address"],
        query,
      });
    },
    reset() {
      this.$refs.sellPointForm.reset();
      this.$store.commit("RESET_SELL_POINTS_DTO");
    },
    ...mapActions(["addSellPoint"]),
  },
};
</script>

<style></style>
